import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'zaIdFields',
    'passportFields',
    'zaIdRadio',
    'passportRadio'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {
    this.toggle()
  }

  // ==== Controllers

  // ==== Actions

  change() {
    this.toggle()
    this.clear()
  }

  // ==== Getters

  get isZa() {
    return this.zaIdRadioTarget.checked
  }

  get isPassport() {
    return this.passportRadioTarget.checked
  }

  // ==== Setters

  // ==== Private

  toggle() {
    this.toggleFields(this.zaIdFieldsTarget, this.isZa)
    this.toggleFields(this.passportFieldsTarget, this.isPassport)
  }

  toggleFields(el, value) {
    el.hidden = !value
    for (const input of el.querySelectorAll('input')) {
      input.disabled = !value
    }
  }

  clear() {
    this.clearFields(this.zaIdFieldsTarget)
    this.clearFields(this.passportFieldsTarget)
  }

  clearFields(el) {
    for (const input of el.querySelectorAll('input')) {
      input.value = ''
    }
    for (const wrapper of el.querySelectorAll('.-error')) {
      wrapper.classList.remove('-error')
    }
    for (const error of el.querySelectorAll('.a-ds2-input__error')) {
      error.remove()
    }
  }

  // ==== Channels

}
