import Rails     from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'
import LocalTime from 'local-time'
import zxcvbn    from 'zxcvbn'

import 'what-input'

// ==== Stimulus

import '../controllers'

// ==== lib

import '../lib'

// ==== Turbo

window.Turbo = Turbo

// ==== zxcvbn

window.zxcvbn = zxcvbn

// ==== LocalTime

LocalTime.config.i18n.en.date.on = '{date}'
LocalTime.config.i18n.en.date.formats.thisYear = '%b %e at %H:%M'
LocalTime.config.i18n.en.time.formats.default = '%H:%M'
LocalTime.config.i18n.en.datetime.formats.default = '%B %e, %Y at %H:%M'
LocalTime.config.i18n.en.date.yesterday = 'Yesterday'
LocalTime.config.i18n.en.date.today = 'Today'
LocalTime.config.i18n.en.date.tomorrow = 'Tomorrow'
LocalTime.start()

function setup() {
  return undefined
}

function pwa() {
  if (!navigator.serviceWorker.controller) {
    navigator.serviceWorker.register('/service-worker.js', { scope: './' })
  }
}

function teardown() {
  return undefined
}

const Application = {
  setup,
  teardown,
  pwa
}

document.addEventListener('turbo:load', Application.setup, { once: true })
document.addEventListener('turbo:load', Application.pwa)
document.addEventListener('turbo:render', Application.setup)
document.addEventListener('turbo:before-render', Application.teardown)

// ==== Rails & Turbo

Rails.start()
Turbo.start()
