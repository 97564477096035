import ApplicationController from '../support/application_controller'
import I18n                  from '../support/i18n'
import $fq                   from '../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'bars',
    'lengthMessage',
    'numberMessage',
    'password',
    'passwordInput',
    'strengthIndicator',
    'strengthMessage',
    'toggle',
    'uppercaseMessage'
  ]

  static values = {
    minPasswordLength: Number,
    showPassword:      { type: Boolean, default: true }
  }

  static classes = []

  initialize() {}

  connect() {
    if (this.showPasswordValue === true) {
      this.toggleButton(this.toggleTarget)
    }
    this.checkStrength()
  }

  // ==== Controllers

  // ==== Actions

  checkStrength() {
    if (!this.hasStrengthIndicatorTarget) return

    const password = this.passwordInputTarget.value
    const score    = this.score(password)
    const scoreKey = this.scoreRagStatus[score]

    this.clearClasses()

    $fq(this.barsTarget).addClass(this.ragStatuses[scoreKey])
    $fq(this.strengthMessageTarget).innerHTML(this.i18nMessages[scoreKey])

    if (password.length >= this.minPasswordLengthValue) {
      $fq(this.lengthMessageTarget).addClass('-checked')
    }

    if (this.containsUppercaseLetter(password)) {
      $fq(this.uppercaseMessageTarget).addClass('-checked')
    }

    if (this.containsNumber(password)) {
      $fq(this.numberMessageTarget).addClass('-checked')
    }
  }

  removeError() {
    const errorElement = this.passwordTarget.querySelector('.a-ds2-input__error')

    if (errorElement != null) {
      $fq(this.passwordTarget).removeClass('-error')
      $fq(errorElement).hide()
    }
  }

  toggle(event) {
    this.toggleButton(event.currentTarget)
  }

  // ==== Getters

  get i18nMessages() {
    const scope     = 'devise.passwords.strength_indicator'
    const minLength = this.minPasswordLengthValue

    return {
      initial:  I18n.t(`${scope}.initial_html`, { length: minLength }),
      veryWeak: I18n.t(`${scope}.very_weak_html`, { length: minLength }),
      weak:     I18n.t(`${scope}.weak_html`, { length: minLength }),
      strong:   I18n.t(`${scope}.strong_html`, { length: minLength })
    }
  }

  get ragStatuses() {
    return {
      initial:  '',
      veryWeak: '-rag-status-r',
      weak:     '-rag-status-a',
      strong:   '-rag-status-g'
    }
  }

  get scoreRagStatus() {
    return {
      0: 'initial',
      1: 'veryWeak',
      2: 'veryWeak',
      3: 'weak',
      4: 'strong'
    }
  }

  // ==== Setters

  // ==== Private

  score(password) {
    return this.strengthScore(password)
  }

  strengthScore(password) {
    let score = 0

    if (password.length === this.minPasswordLengthValue) {
      score += 1
    } else if (password.length > this.minPasswordLengthValue) {
      score += 2
    }

    if (this.containsUppercaseLetter(password)) { score += 1 }

    if (this.containsNumber(password)) { score += 1 }

    return score
  }

  containsUppercaseLetter(password) {
    return password.match(/[A-Z]/)
  }

  containsNumber(password) {
    return password.match(/\d+/g)
  }

  toggleButton(button) {
    const input     = this.passwordInputTarget
    const inputType = input.getAttribute('type')

    if (inputType === 'password') {
      input.setAttribute('type', 'text')
      button.innerHTML = button.getAttribute('data-password-on')
    } else if (inputType === 'text') {
      input.setAttribute('type', 'password')
      button.innerHTML = button.getAttribute('data-password-off')
    }
  }

  clearClasses() {
    $fq(this.barsTarget).removeClass('-rag-status-r')
    $fq(this.barsTarget).removeClass('-rag-status-a')
    $fq(this.barsTarget).removeClass('-rag-status-g')
    $fq(this.lengthMessageTarget).removeClass('-checked')
    $fq(this.numberMessageTarget).removeClass('-checked')
    $fq(this.uppercaseMessageTarget).removeClass('-checked')
  }

  // ==== Channels

}
