import ApplicationController from '../../frontend/support/application_controller'
import $fq                   from '../../frontend/support/fake_query'

export default class extends ApplicationController {

  static targets = []

  static values = {
    autoCloseTime: Number,
    autoOpen:      Boolean,
    closeOnClick:  Boolean,
    closeOnEsc:    Boolean
  }

  static classes = [
    'open'
  ]

  initialize() {
    this.bindCloseOnEsc()
    this.handleAutoClose()
  }

  connect() {}

  // ==== Controllers

  // ==== Actions

  close() {
    $fq(this.element).removeClass(this.openClass)
  }

  // ==== Getters

  get autoCloseTime() {
    return parseInt(this.autoCloseTimeValue, 10)
  }

  // ==== Setters

  // ==== Private

  bindCloseOnEsc() {
    if (!this.closeOnEscValue) return

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey)
        if (isNotCombinedKey) {
          this.close()
        }
      }
    })
  }

  handleAutoClose() {
    if (!this.autoCloseTime || this.autoCloseTime === 0) return

    setTimeout(() => {
      this.close()
    }, this.autoCloseTime)
  }

  // ==== Channels

}
