import { initializeApp }                             from 'firebase/app'
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics'
import ApplicationController                         from '../support/application_controller'

export default class extends ApplicationController {

  static targets = []

  static values = {
    // default value is a random string used to prevents firebase from erroring on connect
    apiKey:                 { type: String, default: 'AIzaSyDgC1CAcaSlyoVuolR-UeGiAT26hgDz3ZY' },
    analyticsProperties:    Object,
    currentUserAnalyticsId: String,
    gaTrackingId:           String
  }

  static classes = []

  initialize() {}

  connect() {
    if (this.gaTrackingIdValue) {
      this.bootFirebase()
      this.setAnalyticsId()
      this.handleActions()
    }
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  get eventName() {
    return this.analyticsPropertiesValue.event_name
  }

  get eventProperties() {
    return this.analyticsPropertiesValue.event_properties || {}
  }

  // ==== Setters

  // ==== Private

  bootFirebase() {
    this.firebaseApp       = initializeApp(this.firebaseConfig())
    this.firebaseAnalytics = getAnalytics(this.firebaseApp)
  }

  setAnalyticsId() {
    if (this.currentUserAnalyticsIdValue) {
      setUserProperties(this.firebaseAnalytics, { analytics_id: this.currentUserAnalyticsIdValue })
    }
  }

  handleActions() {
    if (this.eventName) {
      this.logScreenViewEvent()
    }
  }

  logScreenViewEvent() {
    const eventParams = {
      firebase_screen:       this.eventName,
      firebase_screen_class: 'WebLandingPage'
    }

    Object.assign(eventParams, this.eventProperties)

    logEvent(this.firebaseAnalytics, 'screen_view', eventParams)

    // Leave in for debugging
    // console.log(`Event logged: ${this.screenValue}`)
  }

  firebaseConfig() {
    const config = {
      apiKey:            this.apiKeyValue,
      authDomain:        'kena-dda96.firebaseapp.com',
      projectId:         'kena-dda96',
      storageBucket:     'kena-dda96.appspot.com',
      messagingSenderId: '518231060733',
      appId:             '1:518231060733:web:d053bce32f9d1a402e4e94',
      measurementId:     this.gaTrackingIdValue
    }

    return config
  }

  // ==== Channels

}
