import ApplicationController from '../support/application_controller'
import $fq                   from '../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'minutes',
    'resendButton',
    'seconds',
    'timerMessage'
  ]

  static values = {
    countClicks: Number,
    minutes:     Number
  }

  static classes = []

  initialize() {}

  connect() {
    if (this.countClicksValue >= 3) {
      this.countdown()
    }
  }

  // ==== Controllers

  // ==== Actions

  showResendButton() {
    if (this.countClicksValue >= 3) {
      $fq(this.timerMessageTarget).hide()
      $fq(this.resendButtonTarget).show()
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  countdown() {
    const currentTime  = Date.parse(new Date())
    const endTime      = new Date(currentTime + this.minutesValue * 60 * 1000)
    const self         = this

    function updateClock() {
      const timer = self.timeRemaining(endTime)

      self.minutesTarget.innerHTML = (`0${timer.minutes}`).slice(-2)
      self.secondsTarget.innerHTML = (`0${timer.seconds}`).slice(-2)

      if (timer.total <= 0) {
        clearInterval(timeInterval) // eslint-disable-line no-use-before-define
        self.showResendButton()
      }
    }

    updateClock()
    const timeInterval = setInterval(updateClock, 1000)
  }

  timeRemaining(endTime) {
    const total   = Date.parse(endTime) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)

    return {
      total,
      minutes,
      seconds
    }
  }

  // ==== Channels

}
