import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'button',
    'form',
    'otp1',
    'otp2',
    'otp3',
    'otp4'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  captureOTP(event) {
    const element = event.currentTarget
    const otp     = element.value

    if (otp.length > 1) {
      // reset size from 4 to 1
      this.otp1Target.setAttribute('maxlength', 1)
      this.otp1Target.setAttribute('size', 1)

      otp.split('').slice(0, 4).forEach((value, index) => {
        this[`otp${index + 1}Target`].value = value
      })
    } else {
      const index = parseInt(element.dataset.index, 10)

      if (event.key === 'Backspace') {
        this.focusInput(index, 'backward', 'clear')
      } else if (event.key === 'ArrowLeft') {
        this.focusInput(index, 'backward')
      } else if (event.key === 'ArrowRight') {
        this.focusInput(index, 'forward')
      } else if (event.key && event.key.match('^\\d{1}$')) {
        this.focusInput(index, 'forward', event.key)
      }
    }

    this.submitForm()
  }

  capturePaste(event) {
    const pastedValue = event.clipboardData.getData('text')
    const otpValues   = pastedValue.toString(10).split('').slice(0, 4)

    otpValues.forEach((value, index) => {
      this[`otp${index + 1}Target`].value = value
    })

    this.submitForm()
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  submitForm() {
    // this.formTarget.requestSubmit(this.buttonTarget)
    if (this.otp1Target.value && this.otp2Target.value && this.otp3Target.value && this.otp4Target.value) {
      this.buttonTarget.click()
    }
  }

  focusInput(number, direction, value) {
    const increment    = direction === 'forward' ? 1 : -1
    const currentInput = this[`otp${number}Target`]
    const focusedInput = this[`otp${number + increment}Target`]

    if (focusedInput) {
      focusedInput.focus()
      focusedInput.select()

      if (value === undefined || value === null) return

      if (value === 'clear') {
        currentInput.value = null
      } else {
        currentInput.value = value
      }
    }
  }

  // ==== Channels

}
