import Cleave                from 'cleave.js'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  connect() {
    this.loadFormatter()
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  get formatter() {
    return this.data.get('formatter')
  }

  get countryCode() {
    return this.data.get('countryCode')
  }

  // ==== Setters

  // ==== Private

  loadFormatter() {
    let cleaveOptions = null

    switch (this.formatter) {
      case 'date':
        cleaveOptions = {
          date:        true,
          datePattern: ['Y', 'm', 'd']
        }
        break

      case 'datetime':
        cleaveOptions = {
          numericOnly: true,
          delimiters:  ['/', '/', ' ', ':'],
          blocks:      [4, 2, 2, 2, 2]
        }
        break

      case 'date_year':
        cleaveOptions = {
          numericOnly: true,
          blocks:      [4]
        }
        break

      case 'expiry_date':
        cleaveOptions = {
          numericOnly: true,
          blocks:      [4, 2],
          delimiters:  ['/']
        }
        break

      case 'float':
        cleaveOptions = {
          numeral:            true,
          numeralDecimalMark: '.',
          delimiter:          ''
        }
        break

      case 'integer':
        cleaveOptions = {
          numericOnly: true,
          blocks:      [100]
        }
        break

      case 'phone':
        this.element.addEventListener('paste', (event) => {
          event.preventDefault()
        })

        cleaveOptions = {
          numericOnly: true,
          blocks:      [10]
        }
        break

      case 'uppercase':
        // The blocks and delimiter values is a "hack" to allow spaces in the
        // input while formatting it as uppercase.
        cleaveOptions = {
          blocks:    [99999],
          delimiter: '',
          uppercase: true
        }
        break

      default:
        break
    }

    // eslint-disable-next-line
    const cleave = new Cleave(this.element, cleaveOptions)
  }

  // ==== Channels

}
