import { Controller } from '@hotwired/stimulus'

class ApplicationController extends Controller {

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find((controller) => controller.context.identifier === identifier)
  }

  getControllerForElement(element, identifier) {
    return this.application.getControllerForElementAndIdentifier(element, identifier)
  }

}

export default ApplicationController
