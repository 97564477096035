import Cleave from 'cleave.js'
import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'input'
  ]

  static values = {}

  static classes = [
    'button'
  ]

  initialize() {}

  connect() {
    this.setInternal = this.setInternal.bind(this)
    this.openDatePicker = this.openDatePicker.bind(this)
    this.createFakeInput()
    this.update()
  }

  disconnect() {
    this.fakeInput.removeEventListener('input', this.setInternal)
    this.fakeInput.remove()
  }

  // ==== Controllers

  // ==== Actions

  update() {
    if (this.value) {
      this.fakeValue = this.value.split('-').reverse().join('/')
    } else {
      this.fakeValue = ''
    }
  }

  // ==== Getters

  get value() {
    return this.inputTarget.value
  }

  get fakeValue() {
    return this.fakeInput.value
  }

  // ==== Setters

  set value(v) {
    this.inputTarget.value = v
  }

  set fakeValue(v) {
    this.fakeInput.value = v
  }

  // ==== Private

  createFakeInput() {
    this.fakeInput = document.createElement('input')
    this.fakeInput.type = 'tel'
    this.fakeInput.placeholder = 'DD/MM/YYYY'
    this.fakeCleave = new Cleave(this.fakeInput, {
      date:        true,
      delimiter:   '/',
      datePattern: ['d', 'm', 'Y']
    })
    this.fakeInput.addEventListener('input', this.setInternal)
    this.inputTarget.insertAdjacentElement('beforebegin', this.fakeInput)
  }

  setInternal() {
    if (/\d\d\/\d\d\/\d\d\d\d/.test(this.fakeValue)) {
      this.value = this.fakeValue.split('/').reverse().join('-')
    } else {
      this.value = ''
    }
  }

  openDatePicker() {
    this.inputTarget.focus()
  }

  // ==== Channels

}
