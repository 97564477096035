import Cleave                from 'cleave.js'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = []

  initialize() {}

  connect() {
    this.cleaveInstance()
    this.format()
  }

  // ==== Controllers

  // ==== Actions

  format() {
    const number = this.element.value

    if (!number) return

    if (parseInt(number.charAt(0), 10) === 0) {
      this.cleave.properties.blocks    = [3, 3, 4]
      this.cleave.properties.maxLength = 10
    } else {
      this.cleave.properties.blocks    = [2, 3, 4]
      this.cleave.properties.maxLength = 9
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  cleaveInstance() {
    this.cleave = new Cleave(this.element, {
      numericOnly: true,
      blocks:      [2, 3, 4],
      delimiters:  [' ']
    })
  }

  // ==== Channels

}
