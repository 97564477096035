import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'redirectButton',
    'redirectText'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {
    setTimeout(() => { this.redirectLocation() }, 500)
    this.timeOutText = setTimeout(() => { this.showText() }, 1000)
  }

  disconnect() {
    clearTimeout(this.timeOutText)
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  redirectLocation() {
    this.redirectButtonTarget.click()
  }

  showText() {
    this.redirectTextTarget.classList.remove('u-hide')
  }

  // ==== Channels

}
