import ApplicationController from '../support/application_controller'
import $fq                   from '../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'text'
  ]

  static values = {}

  static classes = [
    'hide'
  ]

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggleText(event) {
    const element = event.currentTarget

    if (element.value === 'locked' || element.value === 'suspended') {
      $fq(this.textTarget).removeClass(this.hideClass)
    } else {
      $fq(this.textTarget).addClass(this.hideClass)
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
