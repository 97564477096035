import ApplicationController from '../../frontend/support/application_controller'
import $fq                   from '../../frontend/support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'glyph',
    'text'
  ]

  static values = {
    enabled: Boolean,
    loading: Boolean,
    timeout: Number,
    href:    String
  }

  static classes = [
    'loading'
  ]

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  loading(event) {
    if (this.nodeName === 'A') {
      this.handleAnchor(event)
    } else if (this.nodeName === 'BUTTON' || this.nodeName === 'INPUT') {
      this.handleButton()
    }
  }

  // ==== Getters

  get nodeName() {
    return this.element.nodeName
  }

  // ==== Setters

  // ==== Private

  handleAnchor(event) {
    // Prevent link from being clicked again if the href has been removed
    if (!this.element.hasAttribute('href')) event.preventDefault()

    $fq(this.element).addClass(this.loadingClass)

    // Re-enable link after `this.timeourValue`ms. Default 27 seconds.
    setTimeout(() => {
      this.element.setAttribute('href', this.hrefValue)
      this.element.removeAttribute('aria-disabled')
      $fq(this.element).removeClass(this.loadingClass)
      this.hrefValue = undefined
    }, this.timeoutValue)

    // Small timeout to allow the link to serialize and perform its action
    // before removing the href
    setTimeout(() => {
      this.hrefValue = this.element.href
      this.element.setAttribute('aria-disabled', 'true')
      this.element.removeAttribute('href')
    }, 13)
  }

  handleButton(event) {
    // Prevent button from being clicked again if it is disabled
    if (this.element.disabled) event.preventDefault()

    $fq(this.element).addClass(this.loadingClass)

    // Re-enable button after `this.timeourValue`ms. Default 27 seconds.
    setTimeout(() => {
      this.element.disabled = false
      $fq(this.element).removeClass(this.loadingClass)
    }, this.timeoutValue)

    // Small timeout to allow the button to serialize and perform its action
    // before disabling.
    setTimeout(() => {
      this.element.disabled = true
    }, 13)
  }

  // ==== Channels

}
